import * as React from "react";
import PlaceholderImage from "../../../images/ourteam-1.png";
import { Col } from "react-bootstrap";

// markup
const OurTeamItem = () => {
  return (
    <Col className="ourteam-item" xs={6} sm={6} lg={4} xl={3}>
      <div className="ourteam-item-img">
        <img alt="" src={PlaceholderImage} />
      </div>
      <h4>Noemi Mercurelli</h4>
      <p>Designer</p>
    </Col>
  );
};

export default OurTeamItem;
