import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../Layout/Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PlaceholderImage from "../../images/project-1.png";

function CustomNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow next" style={{ ...style }} onClick={onClick} />
  );
}

function CustomPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow prev" style={{ ...style }} onClick={onClick} />
  );
}

// markup
const Projects = () => {
  const settings = {
    centerMode: false,
    infinite: true,
    dots: false,
    slidesToShow: 4,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Container className="projects">
      <Row>
        <Col
          xs={12}
          className="order-2 order-lg-1 d-flex justify-content-lg-end"
        >
          <button className="btn btn-outline-primary cta mb-4">
            View All Projects
          </button>
        </Col>
        <Col xs={12} lg={3} className="order-1 order-lg-2 position-relative">
          <Title className="rotate" title="Projects"></Title>
        </Col>
        <Col xs={12} lg={9} className="order-3">
          <Slider {...settings} className="projects-slider">
            <div className="projects-item">
              <div className="projects-img">
                <img src={PlaceholderImage} />
              </div>
              <button className="h4 cta">St Phillips Cessnock</button>
            </div>
            <div className="projects-item">
              <div className="projects-img">
                <img src={PlaceholderImage} />
              </div>
              <button className="h4 cta">Little Zaks Roselle</button>
            </div>
            <div className="projects-item">
              <div className="projects-img">
                <img src={PlaceholderImage} />
              </div>
              <button className="h4 cta">Bacchus Marsh Grammar</button>
            </div>
            <div className="projects-item">
              <div className="projects-img">
                <img src={PlaceholderImage} />
              </div>
              <button className="h4 cta">Belair Primary School</button>
            </div>
            <div className="projects-item">
              <div className="projects-img">
                <img src={PlaceholderImage} />
              </div>
              <button className="h4 cta">St Phillips Cessnock</button>
            </div>
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
