import * as React from "react";
import { Accordion } from "react-bootstrap";

// markup
const FAQItem = ({ title }) => {
  return (
    <Accordion className="custom-accordion" defaultActiveKey={`${title}-0`}>
      {title && <h3 className="subtitle subtitle-sm">{title}</h3>}
      <Accordion.Item eventKey={`${title}-0`}>
        <Accordion.Header>
          Aliquam erat volutpat suspendisse ultrices felis?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Proin vitae volutpat purus. Donec luctus gravida diam vel sodales.
            Maecenas tincidunt elit rutrum ligula ullamcorper accumsan. Integer
            elementum porta quam eu dignissim. Curabitur pellentesque dui eget
            lectus vehicula, non auctor neque tempus.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={`${title}-1`}>
        <Accordion.Header>
          Etiam est odio, auctor in est vitae tempus porttitor elit?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Proin vitae volutpat purus. Donec luctus gravida diam vel sodales.
            Maecenas tincidunt elit rutrum ligula ullamcorper accumsan. Integer
            elementum porta quam eu dignissim. Curabitur pellentesque dui eget
            lectus vehicula, non auctor neque tempus.
          </p>
          <p>
            Proin vitae volutpat purus. Donec luctus gravida diam vel sodales.
            Maecenas tincidunt elit rutrum ligula ullamcorper accumsan. Integer
            elementum porta quam eu dignissim. Curabitur pellentesque dui eget
            lectus vehicula, non auctor neque tempus.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={`${title}-2`}>
        <Accordion.Header>
          Mauris ultrices pulvinar metus sed viverra nibh?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Proin vitae volutpat purus. Donec luctus gravida diam vel sodales.
            Maecenas tincidunt elit rutrum ligula ullamcorper accumsan. Integer
            elementum porta quam eu dignissim. Curabitur pellentesque dui eget
            lectus vehicula, non auctor neque tempus.
          </p>
          <p>
            Proin vitae volutpat purus. Donec luctus gravida diam vel sodales.
            Maecenas tincidunt elit rutrum ligula ullamcorper accumsan. Integer
            elementum porta quam eu dignissim. Curabitur pellentesque dui eget
            lectus vehicula, non auctor neque tempus.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FAQItem;
