import React, { useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import Title from "../Layout/Title";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";

// markup
const GalleryBlock = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Container>
      <Title className="text-center" title="Gallery Block"></Title>
      <div className="gallery-wrap">
        <Gallery
          photos={photos}
          onClick={openLightbox}
          margin={10}
          targetRowHeight={447}
        />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Container>
  );
};

export default GalleryBlock;
