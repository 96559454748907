import * as React from "react";
import { Col } from "react-bootstrap";
import PlaceholderImage from "../../../images/category-1.png";

// markup
const GalleryCategoryItem = () => {
  return (
    <Col className="gallery-category-item" xs={6} md={6} lg={4}>
      <a className="d-block text-decoration-none" href="#">
        <div className="gallery-category-item-image">
          <img alt="" src={PlaceholderImage} />
        </div>
        <h4>Messy Play</h4>
      </a>
    </Col>
  );
};

export default GalleryCategoryItem;
