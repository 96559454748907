import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import GalleryCategoriesItem from "./GalleryCategoriesItem";

// markup
const GalleryCategories = () => {
  return (
    <Container>
      <Title className="text-center" title="Gallery Categories Block"></Title>
      <div className="gallery-categories">
        <Row>
          <GalleryCategoriesItem />
          <GalleryCategoriesItem />
          <GalleryCategoriesItem />
          <GalleryCategoriesItem />
          <GalleryCategoriesItem />
          <GalleryCategoriesItem />
        </Row>
      </div>
    </Container>
  );
};

export default GalleryCategories;
