import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import TimelineObserver from "react-timeline-animation";
import PlaceholderImage from "../../images/timeline-1.png";

// timeline
const Timeline = ({ setObserver, callback }) => {
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);

  const triggerVisible = () => {
    setVisible1(true);
  };

  const triggerVisible2 = () => {
    setVisible2(true);
  };

  const triggerVisible3 = () => {
    setVisible3(true);
  };

  useEffect(() => {
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    setObserver(circle1.current, triggerVisible);
    setObserver(circle2.current, triggerVisible2);
    setObserver(circle3.current, triggerVisible3);
  }, []);

  return (
    <div className="timeline-wrapper">
      <div className="timeline-item">
        <div id="timeline1" ref={timeline1} className="timeline-stroke">
          <div id="circle1" ref={circle1} className="circle" />
        </div>
        <div
          className={`timeline-content${visible1 === true ? " visible" : ""}`}
        >
          <div className="timeline-content-img">
            <h3>2007</h3>
            <img alt="" src={PlaceholderImage} />
          </div>
          <div className="timeline-content-inner">
            <h4 className="h5">Sed finibus dui</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              laoreet euismod purus, ac commodo eros dignissim vitae. Sed
              ultricies tempor nisi. In varius cursus semper. Fusce quis dolor
              venenatis, laoreet ipsum at, venenatis mauris.
            </p>
            <a href="#" className="cta">
              Read More
            </a>
          </div>
        </div>
      </div>

      <div className="timeline-item">
        <div id="timeline2" ref={timeline2} className="timeline-stroke">
          <div id="circle2" ref={circle2} className="circle" />
        </div>
        <div
          className={`timeline-content${visible2 === true ? " visible" : ""}`}
        >
          <div className="timeline-content-img">
            <h3>2010</h3>
            <img alt="" src={PlaceholderImage} />
          </div>
          <div className="timeline-content-inner">
            <h4 className="h5">Sed finibus dui</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              laoreet euismod purus, ac commodo eros dignissim vitae. Sed
              ultricies tempor nisi. In varius cursus semper. Fusce quis dolor
              venenatis, laoreet ipsum at, venenatis mauris.
            </p>
            <a href="#" className="cta">
              Read More
            </a>
          </div>
        </div>
      </div>

      <div className="timeline-item">
        <div id="timeline3" ref={timeline3} className="timeline-stroke">
          <div id="circle3" ref={circle3} className="circle" />
        </div>
        <div
          className={`timeline-content${visible3 === true ? " visible" : ""}`}
        >
          <div className="timeline-content-img">
            <h3>2015</h3>
            <img alt="" src={PlaceholderImage} />
          </div>
          <div className="timeline-content-inner">
            <h4 className="h5">Sed finibus dui</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              laoreet euismod purus, ac commodo eros dignissim vitae. Sed
              ultricies tempor nisi. In varius cursus semper. Fusce quis dolor
              venenatis, laoreet ipsum at, venenatis mauris.
            </p>
            <a href="#" className="cta">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// markup
const TimelineBlock = () => {
  return (
    <Container>
      <Title
        className="text-center"
        subTitle="Testing"
        title="Timeline Block"
      ></Title>
      <div className="timeline-wrapper">
        <Row className="justify-content-center">
          <Col xs={12} lg={11} xl={10} xxl={9}>
            <div className="timeline-item blank">
              <div className="timeline-stroke" />
            </div>
            <TimelineObserver
              initialColor="#e5e5e5"
              fillColor="black"
              handleObserve={(setObserver) => (
                <Timeline
                  className="timeline-stroke"
                  setObserver={setObserver}
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TimelineBlock;
