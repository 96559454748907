import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Select from "react-select";
import Title from "../Layout/Title";
import OurTeamItem from "./OurTeamItem";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? "rgba(0, 0, 0, 0.5)"
      : isSelected
      ? "rgba(0, 0, 0, 1)"
      : "rgba(0, 0, 0, 0)",
    color: isFocused ? "#ffffff" : isSelected ? "#ffffff" : undefined,
  }),
};

const options = [
  { value: "all", label: "All" },
  { value: "designer", label: "Designer" },
  { value: "developer", label: "Developer" },
];

// markup
const OurTeam = () => {
  return (
    <Container className="ourteam">
      <Title className="text-center" title="Our Team">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet
          euismod purus, ac commodo eros dignissim vitae.{" "}
        </p>
      </Title>
      <div className="filter">
        <div className="filter-select">
          <p className="h5">SORT BY</p>
          <Select
            defaultValue={{ value: "all", label: "All" }}
            isSearchable={false}
            styles={customStyles}
            options={options}
          />
        </div>
      </div>
      <div className="ourteam-inner">
        <Row>
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
          <OurTeamItem />
        </Row>
      </div>
    </Container>
  );
};

export default OurTeam;
