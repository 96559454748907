import * as React from "react";
import { Container, Table } from "react-bootstrap";
import Title from "../Layout/Title";

// markup
const TableBlock = () => {
  return (
    <Container>
      <Title className="text-center" subTitle="Subheading" title="Table Block">
        <p>
          Nulla vitae bibendum odio. Duis dignissim cursus ex, ut feugiat est
          vestibulum a. Ut sit amet quam sapien. Vestibulum tristique ante vel
          nulla molestie porttitor.
        </p>
      </Title>
      <Table className="table-block" responsive>
        <thead>
          <tr>
            <th width="270">Name/ID</th>
            <th>Table Height</th>
            <th>Age Range</th>
            <th>Code</th>
            <th width="240">Virco</th>
            <th>Seat Height</th>
            <th>Seat Width</th>
            <th>Overall Height</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Analogy Rocker Chair 350mm</td>
            <td>560mm</td>
            <td>4yrs-8yrs</td>
            <td>50110-Colour</td>
            <td>ANROCK14-BLU65-CHRM</td>
            <td>325</td>
            <td>310</td>
            <td>530</td>
          </tr>
          <tr>
            <td>Analogy Rocker Chair 400mm</td>
            <td>610mm</td>
            <td>6yrs-12yrs</td>
            <td>50111-Colour</td>
            <td>ANROCK16-BLU65-CHRM</td>
            <td>375</td>
            <td>350</td>
            <td>620</td>
          </tr>
          <tr>
            <td>Analogy Rocker Chair 450mm</td>
            <td>670mm</td>
            <td>Adult</td>
            <td>50112-Colour</td>
            <td>ANROCK14-BLU65-CHRM</td>
            <td>440</td>
            <td>400</td>
            <td>750</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default TableBlock;
