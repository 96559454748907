import React from "react";
import Title from "../Layout/Title";
import PlaceholderImage from "../../images/fullwidth.png";
import Slider from "react-slick";

function CustomNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow next" style={{ ...style }} onClick={onClick} />
  );
}

function CustomPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow prev" style={{ ...style }} onClick={onClick} />
  );
}
// markup
const FullImage = () => {
  const settings = {
    dots: false,
    slidesToShow: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
  };
  return (
    <>
      <Title className="text-center" title="View Full Image Block"></Title>
      <Slider {...settings} className="full-image-slider">
        <img alt="" src={PlaceholderImage} />
        <img alt="" src={PlaceholderImage} />
        <img alt="" src={PlaceholderImage} />
        <img alt="" src={PlaceholderImage} />
      </Slider>
    </>
  );
};

export default FullImage;
