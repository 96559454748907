import * as React from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Title from "../Layout/Title";
import FAQItem from "./FAQItem";

// markup
const FAQ = () => {
  return (
    <Container>
      <Title
        className="text-center"
        subTitle="Testing"
        title="Frequently Asked Questions"
      >
        <p>You have questions, we have answers</p>
      </Title>
      <Tab.Container id="left-tabs-example" defaultActiveKey="general">
        <Row>
          <Col lg={4} xl={3}>
            <Nav variant="pills" className="flex-column tabs-group">
              <Nav.Item>
                <Nav.Link eventKey="general">General</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="delivery">Delivery & Return</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="warranty">Grocorp Warranty</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={8} xl={9}>
            <Tab.Content>
              <Tab.Pane eventKey="general">
                <FAQItem title="General" />
              </Tab.Pane>
              <Tab.Pane eventKey="delivery">
                <FAQItem title="Delivery & Return" />
              </Tab.Pane>
              <Tab.Pane eventKey="warranty">
                <FAQItem title="Grocorp Warranty" />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default FAQ;
