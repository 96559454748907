import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image1 from "../../images/threeimg-1.png";
import Image2 from "../../images/threeimg-2.png";
import Image3 from "../../images/threeimg-3.png";
import Title from "../Layout/Title";

// markup
const ThreeImages = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={4} lg>
          <Title className="narrow" title="Most Recent Catalogue">
            <p>
              Aliquam libero ipsum, maximus sed magna vitae, cursus pharetra
              turpis. Vestibulum viverra nisi nunc, non pharetra lectus
              condimentum in. Vivamus sed tellus velit. Sed eu varius neque.
              Nullam tellus diam, placerat sed tincidunt laoreet, efficitur sed
              ex. Vivamus eu magna ac quam fermentum accumsan.
            </p>
            <button className="btn btn-outline-primary mb-md-5">
              Download Catalogue
            </button>
          </Title>

          <img src={Image1} className="w-100 mt-3 mt-md-5" alt="" />
        </Col>
        <Col xs={12} md={3} lg={"auto"}>
          <div className="h-100 d-flex align-items-center">
            <img src={Image2} className="w-100 mt-3" alt="" />
          </div>
        </Col>
        <Col xs={12} md={5} lg>
          <img src={Image3} className="w-100 mt-3 mb-3" alt="" />
          <p className="subtitle subtitle-lg">
            “Nam pulvinar efficitur dapibus ut lectus dolor egestas eu interdum
            vel”
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ThreeImages;
