import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Background from "../../images/text-banner.png";
import Title from "../Layout/Title";

function CustomNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="banner-arrow next" style={{ ...style }} onClick={onClick} />
  );
}

function CustomPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="banner-arrow prev" style={{ ...style }} onClick={onClick} />
  );
}

// markup
const TextBanner = () => {
  const settings = {
    centerMode: false,
    infinite: true,
    dots: false,
    slidesToShow: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings} className="banner-slider">
      <div className="banner-item">
        <div className="banner-item-inner">
          <Container>
            <Row className="justify-content-between">
              <Col xs={12} lg={4}>
                <Title title="Flexible School Furniture"></Title>
              </Col>
              <Col xs={12} lg={5}>
                <div className="d-flex flex-column align-items-start">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum quis turpis scelerisque, accumsan orci vitae,
                    pretium nunc.
                  </p>
                  <a href="#" className="btn btn-outline-primary">
                    Discover New Collection
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner-item-image">
          <img src={Background} />
        </div>
      </div>
      <div className="banner-item">
        <div className="banner-item-inner">
          <Container>
            <Row className="justify-content-between">
              <Col xs={12} lg={5}>
                <Title title="Flexible School Furniture"></Title>
              </Col>
              <Col xs={12} lg={5}>
                <div className="d-flex flex-column align-items-start">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum quis turpis scelerisque, accumsan orci vitae,
                    pretium nunc.
                  </p>
                  <a href="#" className="btn btn-outline-primary">
                    Discover New Collection
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner-item-image">
          <img src={Background} />
        </div>
      </div>
      <div className="banner-item">
        <div className="banner-item-inner">
          <Container>
            <Row className="justify-content-between">
              <Col xs={12} lg={5}>
                <Title title="Flexible School Furniture"></Title>
              </Col>
              <Col xs={12} lg={5}>
                <div className="d-flex flex-column align-items-start">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum quis turpis scelerisque, accumsan orci vitae,
                    pretium nunc.
                  </p>
                  <a href="#" className="btn btn-outline-primary">
                    Discover New Collection
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner-item-image">
          <img src={Background} />
        </div>
      </div>
    </Slider>
  );
};

export default TextBanner;
