import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../Layout/Title";
import Promo1 from "../../images/promo-1.png";
import Promo2 from "../../images/promo-2.png";
import Promo3 from "../../images/promo-3.png";

// markup
const Promotional = () => {
  return (
    <Container>
      <Title className="text-center" title="Promotional Block"></Title>
      <Row>
        <Col xs={12} lg={8}>
          <a href="#" className="promotional-big">
            <img alt="" src={Promo1} />
          </a>
        </Col>
        <Col xs={12} lg={4}>
          <div className="promotional-small">
            <a href="#" className="promotional-small-top">
              <img alt="" src={Promo2} />
            </a>
            <a href="#" className="promotional-small-bottom">
              <img alt="" src={Promo3} />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Promotional;
