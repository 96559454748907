import Item1 from "../../images/gallery-1.png";
import Item2 from "../../images/gallery-2.png";
import Item3 from "../../images/gallery-3.png";
import Item4 from "../../images/gallery-4.png";
import Item5 from "../../images/gallery-5.png";
import Item6 from "../../images/gallery-6.png";
import Item7 from "../../images/gallery-7.png";

export const photos = [
  {
    src: Item1,
    width: 826,
    height: 447,
  },
  {
    src: Item2,
    width: 594,
    height: 447,
  },
  {
    src: Item3,
    width: 710,
    height: 447,
  },
  {
    src: Item4,
    width: 710,
    height: 447,
  },
  {
    src: Item5,
    width: 278,
    height: 447,
  },
  {
    src: Item6,
    width: 561,
    height: 447,
  },
  {
    src: Item7,
    width: 561,
    height: 447,
  },
];
