import * as React from "react";
import PlaceholderImage from "../../../images/catalogues-1.png";
import { Col } from "react-bootstrap";

// markup
const CataloguesItem = () => {
  return (
    <Col className="catalogues-item" xs={6} sm={6} lg={4} xl={3}>
      <div className="catalogues-item-img">
        <img src={PlaceholderImage} />
      </div>
      <p>Odyssey Custom Furniture</p>
    </Col>
  );
};

export default CataloguesItem;
