import * as React from 'react'
import { Container, Row } from 'react-bootstrap'
import Title from '../Layout/Title'
import IconsItem from './IconsItem'

// markup
const Icons = () => {
  return (
    <Container>
      <Title
        className="text-center"
        subTitle="Lorem ipsum"
        title="Icons Block"
      ></Title>
      <div className="icons">
        <Row>
          <IconsItem />
          <IconsItem />
          <IconsItem />
        </Row>
        <button className="btn btn-outline-primary btn-w180 mx-auto">
          View More
        </button>
      </div>
    </Container>
  )
}

export default Icons
