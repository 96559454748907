import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../Layout/Title";

// markup
const ThreeColumnContent = () => {
  return (
    <Container>
      <Title subTitle="Subheading" title="Three Column Content"></Title>
      <Row>
        <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et
            turpis nunc. Cras lorem lorem, aliquam viverra congue eu,
            consectetur vel odio. Nam condimentum tortor non suscipit tristique.
            Nullam a lobortis risus. Aliquam non commodo leo.
          </p>
          <p>
            Cras imperdiet luctus enim at lacinia. Aliquam posuere lacus ac
            vestibulum finibus. Vivamus scelerisque leo in mi convallis
            hendrerit. Curabitur eget ante a lacus dignissim pellentesque. In
            aliquam magna lorem, non consequat purus mattis eu.
          </p>
        </Col>
        <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et
            turpis nunc. Cras lorem lorem, aliquam viverra congue eu,
            consectetur vel odio. Nam condimentum tortor non suscipit tristique.
            Nullam a lobortis risus. Aliquam non commodo leo.
          </p>
          <p>
            Cras imperdiet luctus enim at lacinia. Aliquam posuere lacus ac
            vestibulum finibus. Vivamus scelerisque leo in mi convallis
            hendrerit. Curabitur eget ante a lacus dignissim pellentesque. In
            aliquam magna lorem, non consequat purus mattis eu.
          </p>
        </Col>
        <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et
            turpis nunc. Cras lorem lorem, aliquam viverra congue eu,
            consectetur vel odio. Nam condimentum tortor non suscipit tristique.
            Nullam a lobortis risus. Aliquam non commodo leo.
          </p>
          <p>
            Cras imperdiet luctus enim at lacinia. Aliquam posuere lacus ac
            vestibulum finibus. Vivamus scelerisque leo in mi convallis
            hendrerit. Curabitur eget ante a lacus dignissim pellentesque. In
            aliquam magna lorem, non consequat purus mattis eu.
          </p>
        </Col>
      </Row>
      <button className="btn btn-outline-primary btn-w200 mt-3 mt-lg-4">
        Learn More
      </button>
    </Container>
  );
};

export default ThreeColumnContent;
