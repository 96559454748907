import * as React from "react";
import Banner from "../components/Banner";
import FourColumnContent from "../components/FourColumnContent";
import Layout from "../components/Layout";
import OneColumnContent from "../components/OneColumnContent";
import Section from "../components/Layout/Section";
import TextAndImage from "../components/TextAndImage";
import ThreeColumnContent from "../components/ThreeColumnContent";
import TwoColumnContent from "../components/TwoColumnContent";
import Image1 from "../images/text-and-image-1.png";
import Image2 from "../images/text-and-image-2.png";
import TableBlock from "../components/Table";
import Services from "../components/Services";
import Icons from "../components/Icons";
import OurTeam from "../components/OurTeam";
import Catalogues from "../components/Catalogues";
import GalleryCategories from "../components/GalleryCategories";
import GalleryBlock from "../components/Gallery";
import TimelineBlock from "../components/Timeline";
import FAQ from "../components/FAQ";
import Testimonials from "../components/Testimonials";
import FullImage from "../components/FullImage";
import MapBlock from "../components/Map";
import Promotional from "../components/Promotional";
import VideoBlock from "../components/Videos";
import Tile from "../components/Tile";
import FormBlock from "../components/Form";
import OurRange from "../components/OurRange";
import FeaturedProduct from "../components/FeaturedProduct";
import ThreeImages from "../components/ThreeImages";
import Projects from "../components/Projects";
import Subscribe from "../components/Subscribe";
import TextBanner from "../components/TextBanner";
import DocumentBlock from "../components/Document";
import SmallVideoBlock from "../components/SmallVideos";

// markup
const ContentbuilderPage = () => {
  return (
    <Layout>
      <Banner title={"Banner Block"} />
      <Section smallSpacingTop hasDivider>
        <OneColumnContent />
      </Section>
      <Section hasDivider>
        <TwoColumnContent />
      </Section>
      <Section hasDivider>
        <ThreeColumnContent />
      </Section>
      <Section hasDivider>
        <FourColumnContent />
      </Section>
      <Section smallSpacingBottom>
        <TextAndImage imageURL={Image1} />
      </Section>
      <Section noSpacingTop hasDivider>
        <TextAndImage imageLeft imageURL={Image2} />
      </Section>
      <Section noSpacingBottom>
        <TableBlock />
      </Section>
      <Section noSpacingBottom>
        <Services />
      </Section>
      <Section hasDivider>
        <Icons />
      </Section>
      <Section smallSpacingBottom>
        <OurTeam />
      </Section>
      <Section className="bg-gray">
        <Catalogues />
      </Section>
      <Section hasDivider>
        <TimelineBlock />
      </Section>
      <Section>
        <FAQ />
      </Section>
      <Section className="bg-gray">
        <Testimonials />
      </Section>
      <Section smallSpacingBottom hasDivider>
        <GalleryCategories />
      </Section>
      <Section hasDivider>
        <GalleryBlock />
      </Section>
      <Section hasDivider>
        <FullImage />
      </Section>
      <Section noSpacingBottom>
        <MapBlock />
      </Section>
      <Section hasDivider>
        <Promotional />
      </Section>
      <Section hasDivider>
        <VideoBlock />
      </Section>
      <Section hasDivider>
        <Tile />
      </Section>
      <Section>
        <FormBlock />
      </Section>
      <Section noSpacingTop>
        <OurRange />
      </Section>
      <Section className="bg-gray">
        <FeaturedProduct />
      </Section>
      <Section>
        <ThreeImages />
      </Section>
      <Section className="bg-gray" smallSpacingBottom>
        <Projects />
      </Section>
      <Section smallSpacingBottom noSpacingTop>
        <Subscribe />
      </Section>
      <Section noSpacingBottom noSpacingTop>
        <TextBanner />
      </Section>
      <Section noSpacingBottom noSpacingTop>
        <DocumentBlock />
      </Section>
      <Section>
        <SmallVideoBlock />
      </Section>
    </Layout>
  );
};

export default ContentbuilderPage;
