import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import CataloguesItem from "./CataloguesItem";

// markup
const Catalogues = () => {
  return (
    <Container className="catalogues">
      <Title title="Catalogues">
        <p>
          Mauris mattis libero ut ex gravida, id lacinia ipsum vulputate.
          <br />
          Suspendisse et odio interdum, facilisis nulla et, luctus orci.
          <br />
          Proin molestie turpis massa, quis volutpat ex aliquam et.
        </p>
      </Title>
      <div className="catalogues-inner">
        <Row>
          <CataloguesItem />
          <CataloguesItem />
          <CataloguesItem />
          <CataloguesItem />
        </Row>
      </div>
    </Container>
  );
};

export default Catalogues;
