import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import PlaceholderImage from "../../images/ourrange-1.png";

// markup
const OurRange = () => {
  return (
    <div className="container-md horizontal-wrap">
      <Title className="text-center" title="Our Range"></Title>
      <div className="ourteam-inner">
        <Row className="horizontal-inner">
          <Col md={4} className="horizontal-item">
            <a href="#" className="our-range-item">
              <img src={PlaceholderImage} />
              <div className="our-range-item-inner">
                <p className="h4 cta">EARLY EDUCATION</p>
              </div>
            </a>
          </Col>
          <Col md={4} className="horizontal-item">
            <a href="#" className="our-range-item">
              <img src={PlaceholderImage} />
              <div className="our-range-item-inner">
                <p className="h4 cta">EARLY EDUCATION</p>
              </div>
            </a>
          </Col>
          <Col md={4} className="horizontal-item">
            <a href="#" className="our-range-item">
              <img src={PlaceholderImage} />
              <div className="our-range-item-inner">
                <p className="h4 cta">EARLY EDUCATION</p>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OurRange;
