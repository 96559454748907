import * as React from "react";
import Title from "../Layout/Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PlaceholderImage from "../../images/testimonials-1.png";

function CustomNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow next" style={{ ...style }} onClick={onClick} />
  );
}

function CustomPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="circle-arrow prev" style={{ ...style }} onClick={onClick} />
  );
}

// markup
const Testimonials = () => {
  const settings = {
    centerMode: true,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Title className="text-center" title="Testimonials Block"></Title>
      <Slider {...settings} className="testimonials-slider">
        <div className="testimonials-item">
          <div className="avt">
            <img src={PlaceholderImage} />
          </div>
          <p className="subtitle subtitle-md">
            “Some of the reasons we chose Grocorp included: • A competitive
            offer • Innovative solution • End to End approach consisting of
            services from room planning to rubbish removal As Bacchus Marsh
            Grammar’s Property Manager, I have no hesitation recommending
            Grocorp for any furniture requirements.”
          </p>
          <p className="h5">Nam-Ha Quach</p>
          <p>Property Manager</p>
        </div>
        <div className="testimonials-item">
          <div className="avt">
            <img src={PlaceholderImage} />
          </div>
          <p className="subtitle subtitle-md">
            We have received the Ottomans and they look great in the classroom.
            Very happy with your product and service😊”
          </p>
          <p className="h5">Debbie Black</p>
          <p>Administration</p>
        </div>
        <div className="testimonials-item">
          <div className="avt">
            <img src={PlaceholderImage} />
          </div>
          <p className="subtitle subtitle-md">
            “I was greatly impressed by the professionalism and customer service
            given from Grocorp (especially allowing us to trial the furniture in
            our library) and would not hesitate to recommend this company to
            other schools
          </p>
          <p className="h5">Kafren Alexander</p>
          <p>Teacher Librarian</p>
        </div>
        <div className="testimonials-item">
          <div className="avt">
            <img src={PlaceholderImage} />
          </div>
          <p className="subtitle subtitle-md">
            “Some of the reasons we chose Grocorp included: • A competitive
            offer • Innovative solution • End to End approach consisting of
            services from room planning to rubbish removal As Bacchus Marsh
            Grammar’s Property Manager, I have no hesitation recommending
            Grocorp for any furniture requirements.”
          </p>
          <p className="h5">Nam-Ha Quach</p>
          <p>Property Manager</p>
        </div>
      </Slider>
    </>
  );
};

export default Testimonials;
