import * as React from "react";
import { Container } from "react-bootstrap";
import Title from "../Layout/Title";
import PlaceholderImage from "../../images/map.png";

// markup
const MapBlock = () => {
  return (
    <Container>
      <Title className="text-center small-spacing" title="Map Block">
        <p className="subtitle subtitle-lg">Grocorp Australia</p>
        <p>
          10 Martini Parade, Lithgow NSW
          <br />
          2790, Australia
        </p>
        <p>ABN. 78 750 545 560</p>
        <p>T. (02) 6350 7000 </p>
        <p>E. sales@grocorp.net.au</p>
      </Title>
      <div className="map-wrapper">
        <img src={PlaceholderImage} />
      </div>
    </Container>
  );
};

export default MapBlock;
