import React, { useState } from "react";
import { Container, Row, Col, Modal, Ratio } from "react-bootstrap";
import Title from "../Layout/Title";
import Icon from "../../images/icons.svg";
import PlaceholderImage from "../../images/fullwidth.png";

function VideoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Ratio aspectRatio="16x9">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/LILLG_73mL0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Ratio>
      </Modal.Body>
    </Modal>
  );
}

// markup
const VideoBlock = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Container>
        <Title
          className="text-center"
          subTitle="Subheading"
          title="Videos Block"
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet
            euismod purus, ac commodo eros dignissim vitae.{" "}
          </p>
        </Title>
        <Row className="justify-content-center">
          <Col xs={12} lg={10}>
            <div className="video-tile">
              <div className="video-tile-inner">
                <img src={PlaceholderImage} alt="" />
                <button className="btn-play" onClick={() => setModalShow(true)}>
                  <svg focusable="false" width="18" height="16">
                    <use xlinkHref={`${Icon}#icon-play`}></use>
                  </svg>
                </button>
              </div>
              <p className="text-center">Backsaver Cot</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={6} lg={6}>
            <div className="video-tile">
              <div className="video-tile-inner">
                <img src={PlaceholderImage} alt="" />
                <button className="btn-play" onClick={() => setModalShow(true)}>
                  <svg focusable="false" width="18" height="16">
                    <use xlinkHref={`${Icon}#icon-play`}></use>
                  </svg>
                </button>
              </div>
              <p>Transition Trundle Bed</p>
            </div>
          </Col>
          <Col xs={6} lg={6}>
            <div className="video-tile">
              <div className="video-tile-inner">
                <img src={PlaceholderImage} alt="" />
                <button className="btn-play" onClick={() => setModalShow(true)}>
                  <svg focusable="false" width="18" height="16">
                    <use xlinkHref={`${Icon}#icon-play`}></use>
                  </svg>
                </button>
              </div>
              <p>Flexihub Platforms</p>
            </div>
          </Col>
          <Col xs={6} lg={6}>
            <div className="video-tile">
              <div className="video-tile-inner">
                <img src={PlaceholderImage} alt="" />
                <button className="btn-play" onClick={() => setModalShow(true)}>
                  <svg focusable="false" width="18" height="16">
                    <use xlinkHref={`${Icon}#icon-play`}></use>
                  </svg>
                </button>
              </div>
              <p>Learning Curve - Role Play</p>
            </div>
          </Col>
          <Col xs={6} lg={6}>
            <div className="video-tile">
              <div className="video-tile-inner">
                <img src={PlaceholderImage} alt="" />
                <button className="btn-play" onClick={() => setModalShow(true)}>
                  <svg focusable="false" width="18" height="16">
                    <use xlinkHref={`${Icon}#icon-play`}></use>
                  </svg>
                </button>
              </div>
              <p>Learning Curve - Storage</p>
            </div>
          </Col>
        </Row>
        <button className="btn btn-outline-primary btn-w230 mx-auto">
          Discovery More
        </button>
      </Container>
      <VideoModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default VideoBlock;
