import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import ServicesItem from "./ServicesItem";

// markup
const Services = () => {
  return (
    <Container>
      <Title
        className="text-center"
        subTitle="Services Overview"
        title="Our Services"
      >
        <p>
          Nulla vitae bibendum odio. Duis dignissim cursus ex, ut feugiat est
          vestibulum a. Ut sit amet quam sapien. Vestibulum tristique ante vel
          nulla molestie porttitor.
        </p>
      </Title>
      <div className="services">
        <Row>
          <ServicesItem />
          <ServicesItem />
          <ServicesItem />
        </Row>
      </div>
    </Container>
  );
};

export default Services;
