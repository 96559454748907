import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlaceholderImage from "../../images/text-and-image-1.png";
import Title from "../Layout/Title";

// markup
const TextAndImage = ({ imageLeft, imageURL }) => {
  return (
    <Container className={`text-and-image ${imageLeft ? "image-left" : ""}`}>
      <Row className="justify-content-center">
        <Col
          xs={12}
          lg={6}
          xl={5}
          className={`${imageLeft ? "order-lg-2 order-1" : ""}`}
        >
          <div className="text">
            <Title title="About Us"></Title>
            <p className="subtitle subtitle-md">
              Grocorp - Furniture for Early Education and Schools
            </p>
            <p>
              As Australia’s finest educational furniture company, Grocorp
              offers an impressive catalogue of comfortable furniture for any
              learning environment. We believe in giving the very best learning
              experience to kids of all ages, which is why we use only the
              finest materials and designs throughout our wide selection.
            </p>
            <button className="btn btn-outline-primary btn-w180">
              Read More
            </button>
          </div>
        </Col>
        <Col
          xs={12}
          lg={6}
          xl={5}
          className={`${imageLeft ? "order-lg-1 order-2" : ""}`}
        >
          <div className="image">
            <img alt="" src={imageURL ? imageURL : PlaceholderImage} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TextAndImage;
