import * as React from "react";
import { Col } from "react-bootstrap";

// markup
const ServicesItem = () => {
  return (
    <Col className="services-item" xs={12} lg={4}>
      <h3>Design</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet
        euismod purus, ac commodo eros dignissim vitae. Sed ultricies tempor
        nisi. In varius cursus semper.
      </p>
      <a href="#" className="cta">
        Read More
      </a>
    </Col>
  );
};

export default ServicesItem;
