import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import FeaturedProductItem from "./FeaturedProductItem";

// markup
const FeaturedProduct = () => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    fetch('/products.json')
      .then(res => res.json())
      .then(res => {
        setProducts(res.filter(_item => _item.sku === 'Adult Wing Back Chair - Nordvalla Dark Grey'))
      })
  }, [])

  return (
    <Container>
      <Title className="text-center" title="Featured Products"></Title>
      <Row className="justify-content-center">
        {products.length > 0 && products.map(item => <FeaturedProductItem item={item} />)}
      </Row>
      <div className="btn-wrapper">
        <button className="btn btn-outline-primary btn-w240 mx-auto">
          View All Products
        </button>
      </div>
    </Container>
  );
};

export default FeaturedProduct;
