import * as React from "react";
import { Col } from "react-bootstrap";
import PlaceholderImage from "../../../images/icon-1.png";

// markup
const IconsItem = () => {
  return (
    <Col className="icons-item" xs={12} lg={4}>
      <div className="icons-image">
        <img alt="" src={PlaceholderImage} />
      </div>
      <h3>Nulla vitae bibendum odio</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet
        euismod purus, ac commodo eros dignissim vitae.
      </p>
    </Col>
  );
};

export default IconsItem;
